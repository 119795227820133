import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';
import {CountdownComponent, CountdownConfig} from 'ngx-countdown';
import {isPlatformBrowser} from "@angular/common";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {RxwebValidators} from "@rxweb/reactive-form-validators";
import {plainToClass} from "class-transformer";
import {CredentialsApiModel} from "../../../api/models/credentials.api.model";
import {HttpErrorResponse} from "@angular/common/http";
import {ResponseNotificationService} from "../../services/response-notification.service";
import {ResponseErrorsHandlerService} from "../../services/response-errors-handler.service";
import {NewsletterContactRestService} from "../../../api/services/newsletter-contact.rest.service";
import {NewsletterContactApiModel} from "../../../api/models/newsletter-contact.api.model";

const CountdownTimeUnits: Array<[string, number]> = [
  ['Y', 1000 * 60 * 60 * 24 * 365], // years
  ['M', 1000 * 60 * 60 * 24 * 30], // months
  ['D', 1000 * 60 * 60 * 24], // days
  ['H', 1000 * 60 * 60], // hours
  ['m', 1000 * 60], // minutes
  ['s', 1000], // seconds
  ['S', 1], // million seconds
];

@Component({
  selector: 'app-login',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, AfterViewInit {
  get logoType(): string {
    if (this.windowWidth > 991) {
      return 'assets/layout/images/wtg-logo-transparent7-black.webp';
    }
    return 'assets/layout/images/wtg-logo-transparent7-white.webp';
  }

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly translate: TranslateService,
    private readonly fb: UntypedFormBuilder,
    private readonly rns: ResponseNotificationService,
    private readonly reh: ResponseErrorsHandlerService,
    private readonly newsletterContactRS: NewsletterContactRestService
  ) {
  }

  public config: CountdownConfig = {};
  @ViewChild('menu') menuViewChild: ElementRef;
  @ViewChild('cd', {static: false}) private countdown: CountdownComponent;

  public isMenuActive = false;
  public windowWidth: number = 0;
  public date: Date = new Date();
  public version: number = environment.version;
  public text: any;
  public form: UntypedFormGroup;
  public loading: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (isPlatformBrowser(this.platformId)) {
      this.windowWidth = window.innerWidth;
    }
  }


  ngOnInit() {
    this.createForm();
    if (isPlatformBrowser(this.platformId)) {
      this.windowWidth = window.innerWidth;
    }
  }

  async ngAfterViewInit() {
    this.handleTranslationChange().then(() => {
      this.initCountdownConfig();
      this.countdown.begin();
    });
  }

  initCountdownConfig(): void {
    const date = new Date('2025-07-01');
    this.config = {
      leftTime: this.getTimestampIntervalDiffFromNow(date),
      format: `MM DD HH mm ss`,
      formatDate: ({date, formatStr}) => {
        let duration = Number(date || 0);

        return CountdownTimeUnits.reduce((current, [name, unit]) => {
          if (current.indexOf(name) !== -1) {
            const v = Math.floor(duration / unit);
            duration -= v * unit;
            return current.replace(new RegExp(`(${name}+)(?![^<>]*>|[^>]*<\/[a-z]+>)`, 'g'), (match: string) => {
              return '<span class="inline-flex flex-column fs-large p-2"><span class="text-primary">' +
                v.toString().padStart(match.length, '0') + '</span><span>' +
                this.getTranslatedMonthFromObj(name) +
                '</span></span>';
            });
          }
          return current;
        }, formatStr);
      },
    };
  }

  smoothScroll(id) {
    document.querySelector(id).scrollIntoView({
      behavior: 'smooth'
    });
  }

  onMenuButtonClick(e: Event) {
    e.preventDefault();
    e.stopImmediatePropagation();
    const menu = this.menuViewChild.nativeElement;

    if (this.isMenuActive) {
      this.addClass(menu, 'fadeOutUp');
      this.isMenuActive = false;
    } else {
      this.addClass(menu, 'menu-active fadeInDown');
      this.isMenuActive = true;
    }
  }

  closeMenu(): void {
    this.isMenuActive = false;
    this.removeClass(this.menuViewChild.nativeElement, 'menu-active fadeInDown fadeOutUp');
  }

  onMenuAnimationEnd() {
    const menu = this.menuViewChild.nativeElement;

    if (this.isMenuActive) {
      this.removeClass(menu, 'fadeInDown');
    } else {
      this.removeClass(menu, 'menu-active fadeOutUp');
    }
  }

  addClass(element, classNames) {
    const classNamesArr = classNames.split(' ');
    for (let i = 0; i < classNamesArr.length; i++) {
      const className = classNamesArr[i];
      if (element.classList) {
        element.classList.add(className);
      } else {
        element.className += ' ' + className;
      }
    }
  }

  removeClass(element, classNames) {
    const classNamesArr = classNames.split(' ');
    for (let i = 0; i < classNamesArr.length; i++) {
      const className = classNamesArr[i];
      if (element.classList) {
        element.classList.remove(className);
      } else {
        element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
      }
    }
  }

  public getTranslatedMonthFromObj(key: string): string {
    return this.text[key].toLowerCase();
  }

  public async handleTranslationChange(): Promise<void> {
    this.text = await this.translateTimer();
  }

  private async translateTimer(): Promise<any> {
    const template: object = {
      M: 'misc.months.short',
      D: 'misc.days.short',
      H: 'misc.hours.short',
      m: 'misc.minutes.short',
      s: 'misc.seconds.short',
    };

    return await this.translate.get(Object.values(template)).toPromise().then((res) => {
      const result: object = {};
      Object.entries(template).forEach((entry: [string, string]) => {
        const key = entry[0];
        result[key] = (Object.entries(res).find((resEntry: [string, string]) => resEntry[0] === template[key]))[1];
      });
      return result;
    });
  }

  private getTimestampIntervalDiffFromNow(targetDate: Date): number {
    const now = new Date();
    return (targetDate.getTime() - now.getTime()) / 1000;
  }

  createForm() {
    this.form = this.fb.group(
      {
        firstName: [''],
        lastName: [''],
        email: ['', [RxwebValidators.required(), RxwebValidators.minLength({value: 4}), RxwebValidators.maxLength({value: 255}), RxwebValidators.email()]],
      }
    );
  }

  submit(): void {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }

    this.loading = true;

    this.newsletterContactRS.create(this.form.value as NewsletterContactApiModel)
      .subscribe({
        next: (resp: NewsletterContactApiModel) => {
          this.form.reset();
          this.loading = false;
          this.rns.emitMessage(this.translate.instant('landing.newsletter_success_thanks'));
        },
        error: (errorResponse: HttpErrorResponse) => {
          this.reh.handleFormErrors(this.form, errorResponse);
          this.reh.handleServerErrors(errorResponse);
          this.loading = false;
        }
      });
  }
}
