import {Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID} from '@angular/core';
import {UntypedFormGroup, Validators} from "@angular/forms";
import {RxFormBuilder, RxwebValidators} from "@rxweb/reactive-form-validators";
import {AboutDataProviderService} from "../../../../features/user/project/about/about.data-provider.service";
import {ProjectViewModel} from "../../../../features/user/garage/project.view-model";
import {PostApiModel} from "../../../../api/models/post-api.model";
import {HistoryPostViewModel} from "../../../../features/user/project/about/history-post.view-model";
import {ModalStateEvent} from "../../modal-state-event";
import {ModalState} from "../../modal-state.enum";
import {ModalInterface} from "../../modal.interface";
import {Observable, Subject} from "rxjs";

@Component({
  selector: 'app-history-post',
  templateUrl: './history-post.component.html',
  styleUrls: ['./history-post.component.scss'],
  providers: [AboutDataProviderService]
})
export class HistoryPostComponent implements ModalInterface, OnInit {

  data: any;
  subject: Subject<ModalStateEvent>;
  state: Observable<ModalStateEvent>;

  public form: UntypedFormGroup;
  @Input() public projectVM: ProjectViewModel;
  @Input() public historyPostVM: HistoryPostViewModel
  @Output() onAdd: EventEmitter<HistoryPostViewModel> = new EventEmitter()


  constructor(
    private fb: RxFormBuilder,
    private historyDPS: AboutDataProviderService
  ) { }

  ngOnInit(): void {
    this.projectVM = this.data['projectVM'];
    this.historyPostVM = this.data['historyPost'];
    this.createForm()
    this.state.subscribe((mse: ModalStateEvent) => {
      switch (mse.status) {
        case ModalState.PENDING: this.submit();
      }
    })
  }

  private createForm() {
    const validators: Validators[] = [RxwebValidators.required(), RxwebValidators.minLength({value: 3}), RxwebValidators.maxLength({value: 255})];
    this.form = this.fb.group(
      {
        heading: [null, validators],
        dateRange: [null, [RxwebValidators.required()]],
        content: [null, [RxwebValidators.required(), RxwebValidators.minLength({value: 3})]],
      }
    );

    if (this.historyPostVM) {
      console.log(this.historyPostVM);
      this.form.setValue({
        heading: this.historyPostVM.heading,
        dateRange: [new Date(this.historyPostVM.startDate), new Date(this.historyPostVM.endDate)],
        content: this.historyPostVM.content
      });
    }

    this.form.valueChanges.subscribe(() => {
      if (this.form.valid) {
        this.subject.next(new ModalStateEvent(ModalState.VALID));
      } else {
        this.subject.next(new ModalStateEvent(ModalState.INVALID));
      }
    })
  }

  public submit(): void {
    let req;
    if (this.form.valid) {
      if (!this.historyPostVM) {
        this.historyPostVM = {
          apiModel: new PostApiModel(),
          type: 'HISTORY_POST',
          heading: this.form.get('heading').value,
          startDate: this.form.get('dateRange').value[0],
          endDate: this.form.get('dateRange').value[1],
          content: this.form.get('content').value,
          entryType: 'USER_ENTRY',
          project: this.projectVM.getApiModel()['@id'],
        }
        req = this.historyDPS.createHistoryPost(this.historyPostVM);
      } else {
        this.historyPostVM.heading = this.form.get('heading').value;
        this.historyPostVM.content = this.form.get('content').value;
        this.historyPostVM.startDate = this.form.get('dateRange').value[0];
        this.historyPostVM.endDate = this.form.get('dateRange').value[1];
        req = this.historyDPS.updateHistoryPost(this.historyPostVM);
      }

      req.subscribe((historyPostVM: HistoryPostViewModel) => {
        this.subject.next(new ModalStateEvent(ModalState.SUCCESS, historyPostVM));
        this.onAdd.emit(historyPostVM);

        this.form.reset({
          heading: null,
          dateRange: null,
          content: null,
        });
        this.form.markAsPristine();
        this.form.markAsUntouched();
        this.form.updateValueAndValidity();
      });
    }
  }
}
