import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi} from '@angular/common/http';
import {RequestHttpInterceptor} from './interceptor/request.http.interceptor';
import {ResponseHttpInterceptor} from './interceptor/response.http.interceptor';
import {AuthGuard} from './guards/auth.guard';
import {AuthenticationRestService, UserRestService} from './services';
import {LoaderService} from './services/loader.service';
import {ResponseErrorsHandlerService} from './services/response-errors-handler.service';
import {StorageService} from './services/storage.service';
import {VehicleRestService} from '../api/services/vehicle.rest.service';
import {EmbedService} from './services/embed.service';
import {AuthModule} from '../features/auth/auth.module';
import {OwnerGuard} from './guards/owner.guard';
import {JwtHttpInterceptor} from './interceptor/jwt.http.interceptor';
import {AccessDeniedComponent} from './pages/access-denied/access-denied.component';
import {SharedModule} from '../shared/shared.module';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {ErrorComponent} from './pages/error/error.component';
import {ReactiveFormsModule} from '@angular/forms';
import {NavbarModule} from '../shared/navbar/navbar.module';
import {HomeComponent} from '../features/home/home.component';
import {SiteLayoutComponent} from './layout/site/site-layout.component';
import {CurrentUserResolverService} from './services/current-user.resolver.service';
import {RouterModule} from '@angular/router';
import {QueryParamsService} from './services/query-params.service';
import {Oauth2Guard} from './guards/oauth2.guard';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {MenuService} from '../shared/navbar/app.menu.service';
import {PrivacyComponent} from '../features/privacy/privacy.component';
import {NoAuthLayoutComponent} from '../shared/no-auth-layout/no-auth-layout.component';
import {PrivacyContentComponent} from '../features/privacy/privacy-content/privacy-content.component';
import {LandingComponent} from './layout/landing/landing.component';
import {RankingComponent} from '../features/ranking/ranking.component';
import {AvatarModule} from 'primeng/avatar';
import {LanguageHttpInterceptor} from './interceptor/language.http.interceptor';
import {ScrollTopModule} from 'primeng/scrolltop';
import {QueryService} from './services/query.service';
import {AdminAuthGuard} from './guards/admin-auth.guard';
import {JwtHelperService} from '@auth0/angular-jwt';
import {NoAuthGuard} from './guards/no-auth.guard';
import {CountdownModule} from 'ngx-countdown';
import {Meta, provideClientHydration} from '@angular/platform-browser';
import { SearchResultsListComponent } from './components/search-results-list/search-results-list.component';
import {SwiperModule} from 'swiper/angular';
import {MatTabsModule} from '@angular/material/tabs';
import {SkeletonModule} from "primeng/skeleton";
import {ChipModule} from "primeng/chip";
import {TimezoneHttpInterceptor} from "./interceptor/timezone.http.interceptor";

@NgModule({
  declarations: [
    AccessDeniedComponent,
    PageNotFoundComponent,
    ErrorComponent,
    HomeComponent,
    RankingComponent,
    SiteLayoutComponent,
    PrivacyComponent,
    NoAuthLayoutComponent,
    PrivacyContentComponent,
    LandingComponent,
    SearchResultsListComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    NavbarModule,
    ScrollPanelModule,
    AuthModule,
    RouterModule,
    AvatarModule,
    ScrollTopModule,
    CountdownModule,
    SwiperModule,
    MatTabsModule,
    SkeletonModule,
    ChipModule
  ],
  providers: [
    provideClientHydration(),
    provideHttpClient(
      withInterceptorsFromDi(),
      withFetch()
    ),
    CurrentUserResolverService,
    Meta,
    AuthGuard,
    NoAuthGuard,
    AdminAuthGuard,
    Oauth2Guard,
    OwnerGuard,
    AuthenticationRestService,
    LoaderService,
    UserRestService,
    ResponseErrorsHandlerService,
    StorageService,
    JwtHelperService,
    VehicleRestService,
    EmbedService,
    QueryParamsService,
    MenuService,
    QueryService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimezoneHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseHttpInterceptor,
      multi: true
    },
  ]
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule,
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
