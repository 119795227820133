<form [formGroup]="form">
  <div class="grid grid-nogutter">
    <div class="col-12" style="height: 100%">
      <p-editor
        [placeholder]="'shared.modal.modal_service.project_description.describe_project'|translate"
        [formControlName]="'content'"
        [style]="{'height':'200px'}"
      ></p-editor>
      <!--        <app-html-editor *ngIf="isPlatformBrowser(platformId)" formControlName="content"></app-html-editor>-->
      <div *ngIf="form.get('content').invalid && form.get('content').touched" class="app-form-error">
        <div>{{ form.get('content')['errorMessage'] }}</div>
        <div *ngIf="form.get('content').errors?.custom">{{ form.get('content').errors?.custom }}</div>
      </div>
    </div>
  </div>
</form>
