<p-dialog [showHeader]="isHeaderVisible" [header]="name" [(visible)]="visible" styleClass="app-modal {{styles.slideFrom}} {{styles.modalType}}"
          [style]="{minWidth: '20vw'}" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
          (onHide)="hide()"
>
  <ng-template appModal pTemplate=""></ng-template>
  <p-footer>
    <div *ngIf="hasButtons" class="modal-footer flex justify-content-around" [ngClass]="styles.light ? 'deep-orange-text' : 'text-white'">
      <button pButton
              type="button"
              class="p-button-outlined p-button-danger"
              [label]="'common.button.dismiss' | translate"
              (click)="hide()"
      ></button>
      <button pButton
              type="button"
              class="p-button-outlined p-button-primary"
              label="{{btnSuccessName}}"
              (click)="processAction()"
              [disabled]="btnDisabled"
      ></button>
    </div>
  </p-footer>
</p-dialog>
