<div class="landing-container" (click)="closeMenu()">
  <div id="header" class="section flex flex-column">
    <div class="linear-gradient-top"></div>
    <div class="header-menu-container flex align-items-center justify-content-between">
      <a class="layout-topbar-logo" routerLink="/">
        <img [src]="logoType" alt="wtg watch this garage logo" style="height: 5rem">
      </a>

      <a id="menu-button" href="#" class="lg:hidden" (click)="onMenuButtonClick($event)">
        <i class="pi pi-bars fs-xlarge"></i>
      </a>
      <ul #menu id="menu" class="grayscale" (animationend)="onMenuAnimationEnd()">
        <li><a target="_blank" href="https://www.facebook.com/WatchThisGarage" class="flex p-3">
          <span class="p-button-icon pi pi-facebook fs-xlarge" aria-hidden="true"></span></a></li>
        <li><a target="_blank" href="https://www.instagram.com/watch.this.garage" class="flex p-3">
          <span class="p-button-icon pi pi-instagram fs-xlarge" aria-hidden="true"></span></a></li>
        <li><a (click)="smoothScroll('#header')" class="flex p-3">Intro</a></li>
        <li><a (click)="smoothScroll('#features')" class="flex p-3">{{'landing.features' | translate}}</a></li>
        <li><a (click)="smoothScroll('#promotion')" class="flex p-3">{{'landing.mission'| translate}}</a></li>
        <li><a (click)="smoothScroll('#video')" class="flex pl-3 py-3">Video</a></li>
        <li><a [routerLink]="'/login'" class="flex p-3">{{'landing.login' | translate}}</a></li>
        <li>
          <app-language-dropdown-button [classList]="isMenuActive ? 'p-3' : ''" (languageChanged)="handleTranslationChange()"></app-language-dropdown-button>
        </li>
      </ul>
    </div>
    <div class="header-text flex flex-column align-items-center justify-content-center">
      <h2 class="title-sentence mb-6 white-color grayscale">{{'landing.title' | translate}}</h2>
      <div class="countdown-wrapper text-center">
        <h3>{{'landing.registration_open_on' | translate}}</h3>
        <countdown #cd *ngIf="config" [config]="config"></countdown>
      </div>
      <!--            <button pButton pRipple type="button" label="Sign Up Now" class="p-button-secondary"></button>-->
    </div>
    <div class="linear-gradient-bottom"></div>
  </div>
  <div id="features" class="section flex flex-column align-items-center">
    <h2>{{'landing.sub_title' | translate}}</h2>
    <p class="text-center">{{'landing.intro_content' | translate}}</p>
      <h4 class="text-center">{{'landing.newsletter_slogan' | translate}}</h4>
      <div class="grid w-full max-w-30rem mb-6" [formGroup]="form">
        <div class="col-12 p-fluid">
            <div class="p-float-label p-input-icon-left">
              <i class="pi pi-user"></i>
              <input type="text" pInputText id="first-name" formControlName="firstName">
              <label for="first-name">{{'common.first_name_or_nick' | translate }}</label>
            </div>
        </div>
        <div class="col-12 p-fluid">
            <div class="p-float-label p-input-icon-left">
              <i class="pi pi-at"></i>
              <input type="email" pInputText id="email" formControlName="email">
              <label for="email">{{'common.email' | translate }}</label>
            </div>
            <div class="app-form-error"
                 *ngIf="form.controls['email'].invalid && form.controls['email'].touched">
              <p
                *ngIf="form.controls['email'].errors.required">{{ 'auth.registration.validation.required_email' | translate }}</p>
              <p *ngIf="form.controls['email'].errors.email">{{ 'auth.registration.validation.email' | translate }}</p>
              <p *ngIf="form.controls['email'].errors.custom">{{ form.controls['email'].errors.custom }}</p>
            </div>
        </div>
        <div class="col-12 p-fluid">
          <button pButton pRipple
                  icon="pi pi-envelope"
                  iconPos="left"
                  type="button"
                  (click)="submit()"
                  [outlined]="true"
                  [rounded]="true"
                  [label]="'common.i_sign_up' | translate"
                  [loading]="loading"
          >
          </button>
        </div>
      </div>
    <div id="vintage" class="flex justify-content-center align-items-center">
    </div>
    <div class="grid mt-3 mx-0">
      <div class="col-12 md:col-4 text-center">
        <button pButton pRipple type="button"
                class="feature-button p-button-rounded p-button-plain p-button-text p-button p-component p-button-icon-only">
          <span class="p-button-icon pi pi-pencil fs-xlarge" aria-hidden="true"></span>
        </button>
        <h4>{{'landing.features_slogan_1' | translate}}</h4>
      </div>
      <div class="col-12 md:col-4 text-center">
        <button pButton pRipple type="button"
                class="feature-button p-button-rounded p-button-plain p-button-text p-button p-component p-button-icon-only">
          <span class="p-button-icon pi pi-mobile fs-xlarge" aria-hidden="true"></span>
        </button>
        <h4>{{'landing.features_slogan_2' | translate}}</h4>
      </div>
      <div class="col-12 md:col-4 text-center">
        <button pButton pRipple type="button"
                class="feature-button p-button-rounded p-button-plain p-button-text p-button p-component p-button-icon-only">
          <span class="p-button-icon pi pi-upload fs-xlarge" aria-hidden="true"></span>
        </button>
        <h4>{{'landing.features_slogan_3' | translate}}</h4>
      </div>
      <div class="col-12 md:col-4 text-center">
        <button pButton pRipple type="button"
                class="feature-button p-button-rounded p-button-plain p-button-text p-button p-component p-button-icon-only">
          <span class="p-button-icon pi pi-tag fs-xlarge" aria-hidden="true"></span>
        </button>
        <h4>{{'landing.features_slogan_4' | translate}}</h4>
      </div>
      <div class="col-12 md:col-4 text-center">
        <button pButton pRipple type="button"
                class="feature-button p-button-rounded p-button-plain p-button-text p-button p-component p-button-icon-only">
          <span class="p-button-icon pi pi-palette fs-xlarge" aria-hidden="true"></span>
        </button>
        <h4>{{'landing.features_slogan_5' | translate}}</h4>
      </div>
      <div class="col-12 md:col-4 text-center">
        <button pButton pRipple type="button"
                class="feature-button p-button-rounded p-button-plain p-button-text p-button p-component p-button-icon-only">
          <span class="p-button-icon pi pi-check-circle fs-xlarge" aria-hidden="true"></span>
        </button>
        <h4>{{'landing.features_slogan_6' | translate}}</h4>
      </div>
    </div>
  </div>

  <div id="promotion" class="flex flex-column align-items-center">
    <div class="grid m-0 py-3 pl-xl-6">
      <div
        class="col-12 lg:col-8 flex flex-column justify-content-center align-items-center align-items-lg-start pl-md-6">
        <h2 class="white-color grayscale pb-6">{{'landing.mission_slogan_1' | translate}}</h2>
        <!--                <button pButton pRipple type="button" class="p-button-raised p-button p-component">-->
        <!--                    <span class="p-button-label">Sign Up Now</span>-->
        <!--                </button>-->
      </div>
      <div class="promotion-text col-12 md:mt-6 lg:col-4">
        <div class="card mt-3 mb-0">
          <h3>{{'landing.mission_slogan_2_title' | translate}}</h3>
          <p>{{'landing.mission_slogan_2_content' | translate}}</p>
        </div>

        <div class="card mt-3 mb-0">
          <h3>{{'landing.mission_slogan_3_title' | translate}}</h3>
          <p>{{'landing.mission_slogan_3_content' | translate}}</p>
        </div>

        <div class="card my-3">
          <h3>{{'landing.mission_slogan_4_title' | translate}}</h3>
          <p>{{'landing.mission_slogan_4_content' | translate}}</p>
        </div>
      </div>
    </div>
  </div>

  <!--    <div id="pricing" class="section flex flex-column align-items-center">-->
  <!--        <h2>Pricing</h2>-->
  <!--        <p>Esse delectus sit velit, aspernatur voluptates molestiae, enim recusandae. Odit dicta, maiores quas ad-->
  <!--            nesciunt, illum expedita veritatis illo quam odio id!</p>-->
  <!---->
  <!--        <div class="grid m-0 pricing-content">-->
  <!--            <div class="col-12 xl:col-4">-->
  <!--                <div class="card p-0">-->
  <!--                    <div class="flex flex-column align-items-center indigo-bgcolor white-color p-6 fs-large">-->
  <!--                        <span>BASIC</span>-->
  <!--                        <h1 class="font-bold">$5</h1>-->
  <!--                        <span>Monthly</span>-->
  <!--                    </div>-->
  <!--                    <ul class="options">-->
  <!--                        <li><i class="pi pi-check"></i><span>Responsive</span></li>-->
  <!--                        <li><i class="pi pi-check"></i><span>Push Messages</span></li>-->
  <!--                    </ul>-->
  <!--                </div>-->
  <!--            </div>-->
  <!--            <div class="col-12 xl:col-4">-->
  <!--                <div class="card p-0">-->
  <!--                    <div class="flex flex-column align-items-center pink-bgcolor white-color p-6 fs-large">-->
  <!--                        <span>STANDARD</span>-->
  <!--                        <h1 class="font-bold">$25</h1>-->
  <!--                        <span>Monthly</span>-->
  <!--                    </div>-->
  <!--                    <ul class="options">-->
  <!--                        <li><i class="pi pi-check"></i><span>Responsive</span></li>-->
  <!--                        <li><i class="pi pi-check"></i><span>Push Messages</span></li>-->
  <!--                        <li><i class="pi pi-check"></i><span>7/24 Support</span></li>-->
  <!--                        <li><i class="pi pi-check"></i><span>Free Shipping</span></li>-->
  <!--                    </ul>-->
  <!--                </div>-->
  <!--            </div>-->
  <!--            <div class="col-12 xl:col-4 pricing-box pricing-professional">-->
  <!--                <div class="card p-0">-->
  <!--                    <div class="flex flex-column align-items-center cyan-bgcolor white-color p-6 fs-large">-->
  <!--                        <span>PROFESSIONAL</span>-->
  <!--                        <h1 class="font-bold">$50</h1>-->
  <!--                        <span>Monthly</span>-->
  <!--                    </div>-->
  <!--                    <ul class="options">-->
  <!--                        <li><i class="pi pi-check"></i><span>Responsive</span></li>-->
  <!--                        <li><i class="pi pi-check"></i><span>Push Messages</span></li>-->
  <!--                        <li><i class="pi pi-check"></i><span>7/24 Support</span></li>-->
  <!--                        <li><i class="pi pi-check"></i><span>Free Shipping</span></li>-->
  <!--                        <li><i class="pi pi-check"></i><span>Unlimited Bandwidth</span></li>-->
  <!--                        <li><i class="pi pi-check"></i><span>Unlimited Storage</span></li>-->
  <!--                        <li><i class="pi pi-check"></i><span>Gift Cards</span></li>-->
  <!--                    </ul>-->
  <!--                </div>-->
  <!--            </div>-->
  <!--        </div>-->
  <!--    </div>-->

<!--  <div id="video" class="section flex flex-column align-items-center justify-content-center">-->
<!--    <h2>{{'landing.quick_tour' | translate}}</h2>-->
<!--    <p>{{'landing.quick_tour_in_development' | translate}}</p>-->
<!--    <div class="m-3">-->
<!--      <img src="assets/sass/layout/images/landing/under_construction.png">-->
<!--      &lt;!&ndash;          <iframe width="560" height="315" src="" title="Wirtualna wycieczka" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>&ndash;&gt;-->
<!--    </div>-->
<!--  </div>-->

  <div id="footer" class="section flex align-items-center justify-content-between flex-column lg:flex-row">
    <p>WatchThisGarage v. {{date | date: 'YYYY'}}{{'.' + version}}</p>

    <div class="py-3 flex align-items-center">
      <ul class="my-3 mr-3">
        <li><a target="_blank" href="https://www.facebook.com/WatchThisGarage" class="flex p-3">
          <span class="p-button-icon pi pi-facebook fs-xlarge" aria-hidden="true"></span></a></li>
      </ul>
      <ul class="my-3 mr-3">
        <li><a target="_blank" href="https://www.instagram.com/watch.this.garage/" class="flex p-3">
          <span class="p-button-icon pi pi-instagram fs-xlarge" aria-hidden="true"></span></a></li>
      </ul>
      <ul class="my-3 mr-3">
        <li><a href="#promotion">{{'landing.mission' | translate}}</a></li>
        <!--                <li><a href="#pricing">Pricing</a></li>-->
        <li><a href="#video">Video</a></li>
      </ul>
      <ul class="my-3 ml-3">
        <li><a href="#header">Intro</a></li>
        <li><a href="#features">{{'landing.features' | translate}}</a></li>
      </ul>
    </div>
  </div>
</div>
