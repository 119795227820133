import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable()
export class TimezoneHttpInterceptor implements HttpInterceptor {

  constructor(
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    try {
      return next.handle(this.addTimezone(request));
    } catch (error) {
      return next.handle(request);
    }
  }

  private addTimezone(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        'Accept-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    });
  }
}
