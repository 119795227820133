<div [ngClass]="{'layout-inline-menu': true, 'layout-inline-menu-active':appMain.inlineMenuActive[key]}"
     [ngStyle]="style" [class]="styleClass">
  <div  style="height: 10rem; width: 100%; position: relative"
       [ngStyle]="currentBackgroundStyles"
  >
    <a class="layout-inline-menu-action flex p-dir-row align-items-center" style="height: 100%;"
       [ngClass]="appMain.isHorizontal() ? 'p-3 p-lg:col-1 py-3' : 'p-3'" (click)="onClick($event)"
       [pTooltip]="user.username" [tooltipDisabled]="isTooltipDisabled">
      <div style="padding-top: 5px">
        <app-thumbnail
            [imgSrc]="user.avatar | mediaObject" [circle]="true" [bordered]="true"
            stylesList="width: 62px; height: 62px;"
        ></app-thumbnail>
      </div>
      <span class="flex flex-column" [ngClass]="{'ml-2': !app.isRTL, 'mr-2': app.isRTL}">
            <span class="font-bold">{{ user?.username }}</span>
            <small>{{ user?.email }}</small>
        </span>
<!--      <i class="layout-inline-menu-icon pi pi-angle-down"-->
<!--         [ngClass]="{'ml-auto': !app.isRTL, 'mr-auto': app.isRTL}"></i>-->
    </a>
  </div>


  <ul class="layout-inline-menu-action-panel"
      (click)="onClickNavigationElement()"
      [@menu]="appMain.inlineMenuActive[key] ? isHorizontalActive() ? 'visible' : 'visibleAnimated' : isHorizontalActive() ? 'hidden' : 'hiddenAnimated'">
    <li class="layout-inline-menu-action-item" *ngxPermissionsOnly="['ROLE_ADMIN']" [pTooltip]="'shared.inline_menu.admin_dashboard' | translate" [tooltipDisabled]="isTooltipDisabled">
      <a [routerLink]="['/admin']" class="flex flex-row align-items-center" [attr.tabindex]="tabIndex">
        <i class="fas fa-lock"></i>
        <span>{{'shared.inline_menu.admin_dashboard' | translate}}</span>
      </a>
    </li>
    <li class="layout-inline-menu-action-item" [pTooltip]="'shared.inline_menu.garage' | translate" [tooltipDisabled]="isTooltipDisabled">
      <a [routerLink]="['/', user.slug, 'garage']" class="flex flex-row align-items-center" [attr.tabindex]="tabIndex">
        <i class="fas fa-warehouse"></i>
        <span>{{'shared.inline_menu.garage' | translate}}</span>
      </a>
    </li>
    <li class="layout-inline-menu-action-item" [pTooltip]="'shared.inline_menu.profile' | translate" [tooltipDisabled]="isTooltipDisabled">
      <a [routerLink]="['/', user.slug, 'profile']" class="flex flex-row align-items-center" [attr.tabindex]="tabIndex">
        <i class="pi pi-user pi-fw"></i>
        <span>{{'shared.inline_menu.profile' | translate}}</span>
      </a>
    </li>
    <li class="layout-inline-menu-action-item" [pTooltip]="'shared.inline_menu.settings' | translate" [tooltipDisabled]="isTooltipDisabled">
      <a [routerLink]="['/', user.slug, 'settings']" class="flex flex-row align-items-center" [attr.tabindex]="tabIndex">
        <i class="pi pi-cog pi-fw"></i>
        <span>{{'shared.inline_menu.settings' | translate}}</span>
      </a>
    </li>
    <li class="layout-inline-menu-action-item" [pTooltip]="'shared.inline_menu.support' | translate" [tooltipDisabled]="isTooltipDisabled">
      <a class="flex flex-row align-items-center" [attr.tabindex]="tabIndex">
        <i class="pi pi-compass pi-fw"></i>
        <span>{{'shared.inline_menu.support' | translate}}</span>
      </a>
    </li>
    <li class="layout-inline-menu-action-item" [pTooltip]="'shared.inline_menu.terms' | translate" [tooltipDisabled]="isTooltipDisabled">
      <a class="flex flex-row align-items-center" [attr.tabindex]="tabIndex">
        <i class="pi pi-file-o pi-fw"></i>
        <span>{{'shared.inline_menu.terms' | translate}}</span>
      </a>
    </li>
    <li class="layout-inline-menu-action-item" [pTooltip]="'features.privacy.privacy' | translate" [tooltipDisabled]="isTooltipDisabled">
      <a [routerLink]="['/privacy']"  class="flex flex-row align-items-center" [attr.tabindex]="tabIndex">
        <i class="pi pi-lock pi-fw"></i>
        <span>{{'features.privacy.privacy' | translate}}</span>
      </a>
    </li>
    <li class="layout-inline-menu-action-item" [pTooltip]="'shared.inline_menu.logout' | translate" [tooltipDisabled]="isTooltipDisabled">
      <a [routerLink]="['/login']" class="flex flex-row align-items-center" [attr.tabindex]="tabIndex"
         (click)="logout($event)">
        <i class="pi pi-power-off pi-fw"></i>
        <span>{{'shared.inline_menu.logout' | translate}}</span>
      </a>
    </li>
  </ul>
</div>
