import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {plainToClass, plainToClassFromExist} from "class-transformer";
import {ResultListApiModel} from "../models/result-list.api.model";
import { HttpClient, HttpResponse } from "@angular/common/http";
import {QueryParamsApiModel} from "../models/query-params-api.model";
import {QueryParamsService} from "../../core/services/query-params.service";
import {NewsletterContactApiModel} from "../models/newsletter-contact.api.model";

@Injectable({
  providedIn: 'root'
})
export class NewsletterContactRestService {
  private readonly resource: string = `/newsletter_contacts`;

  constructor(
    private http: HttpClient,
    public params: QueryParamsService
  ) {
  }

  public create(newsletterContactAM: NewsletterContactApiModel): Observable<NewsletterContactApiModel> {
    return this.http.post<NewsletterContactApiModel>(environment.apiUrl + this.resource, newsletterContactAM)
      .pipe(map((result: NewsletterContactApiModel) => plainToClass(NewsletterContactApiModel, result)));
  }

  public put(newsletterContactAM: NewsletterContactApiModel): Observable<NewsletterContactApiModel> {
    return this.http.put<NewsletterContactApiModel>(environment.apiUrl + newsletterContactAM['@id'], newsletterContactAM)
      .pipe(map((result: NewsletterContactApiModel) => plainToClass(NewsletterContactApiModel, result)));
  }

  public get(newsletterContactAM: NewsletterContactApiModel): Observable<NewsletterContactApiModel> {
    return this.http.get<NewsletterContactApiModel>(environment.apiUrl+ newsletterContactAM['@id'])
      .pipe(map((result: NewsletterContactApiModel) => plainToClass(NewsletterContactApiModel, result)));
  }

  public getById(id: number): Observable<NewsletterContactApiModel> {
    return this.http.get<NewsletterContactApiModel>(environment.apiUrl+ this.resource + '/' + id)
      .pipe(map((result: NewsletterContactApiModel) => plainToClass(NewsletterContactApiModel, result)));
  }

  public delete(newsletterContactAM: NewsletterContactApiModel): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(environment.apiUrl + newsletterContactAM['@id']);
  }

  public getCollection(queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel(), resource: string = null): Observable<ResultListApiModel<NewsletterContactApiModel>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<NewsletterContactApiModel>>(environment.apiUrl + (resource || this.resource), {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<NewsletterContactApiModel>(NewsletterContactApiModel), result as object)));
  }
}
