  <form [formGroup]="form">
    <div class="grid m-0">
      <div class="col-12 p-fluid">
        <div class="p-field p-float-label p-input-icon-left">
          <input id="heading" pInputText type="text" formControlName="heading">
          <label for="heading">{{'feature.user.project.history_post.heading' | translate}}</label>
        </div>
        <div *ngIf="form.get('heading').invalid && form.get('heading').touched" class="app-form-error">
          <div>{{form.get('heading')['errorMessage']}}</div>
          <div *ngIf="form.get('heading').errors?.custom">{{form.get('heading').errors?.custom}}</div>
        </div>
      </div>
      <div class="col-12 p-fluid">
        <div class="p-field p-float-label p-input-icon-left">
          <p-calendar inputId="dateRange" selectionMode="range" formControlName="dateRange"></p-calendar>
          <label [for]="'dateRange'">{{'feature.user.project.history_post.date_range' | translate}}</label>
        </div>
        <div *ngIf="form.get('dateRange').invalid && form.get('dateRange').touched" class="app-form-error">
          <div>{{form.get('dateRange')['errorMessage']}}</div>
          <div *ngIf="form.get('dateRange').errors?.custom">{{form.get('dateRange').errors?.custom}}</div>
        </div>
      </div>
<!--      <div class="col-2">-->
<!--        <app-end-cap-->
<!--          [disabled]="form.invalid"-->
<!--          icon="pi pi-arrow-right"-->
<!--          [pointerClass]="'p-button-rounded p-button-outlined p-button-icon-only p-button-primary'"-->
<!--          (onClickTrigger)="submit()"-->
<!--        >-->
<!--        </app-end-cap>-->
<!--      </div>-->
      <div class="col-12">
<!--        <app-html-editor formControlName="content"></app-html-editor>-->
        <p-editor
          [placeholder]="'shared.modal.modal_service.history_post.describe_entry'|translate"
          [formControlName]="'content'"
          [style]="{'height':'200px'}"
        ></p-editor>
        <div *ngIf="form.get('content').invalid && form.get('content').touched" class="app-form-error">
          <div>{{form.get('content')['errorMessage']}}</div>
          <div *ngIf="form.get('content').errors?.custom">{{form.get('content').errors?.custom}}</div>
        </div>
      </div>
      <!--    <div class="col-2">-->
      <!--      <app-end-cap-->
      <!--        [disabled]="content?.length < 3"-->
      <!--        icon="pi pi-arrow-right"-->
      <!--        [pointerClass]="'p-button-rounded p-button-outlined p-button-icon-only p-button-primary'"-->
      <!--        (onClickTrigger)="addPost()"-->
      <!--      >-->
      <!--      </app-end-cap>-->
      <!--    </div>-->
    </div>
  </form>
