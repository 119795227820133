import {Component, Input, OnInit} from '@angular/core';
import {trigger, state, transition, style, animate} from '@angular/animations';
import {AppComponent} from "../../app.component";
import {SiteLayoutComponent} from "../../core/layout/site/site-layout.component";
import {CurrentUserViewModel} from "../../core/models/current-user.view-model";
import {TokenDataProviderService} from "../../core/services/token-data-provider.service";
import {MediaObjectPipe} from "../../core/pipes/media-object.pipe";

@Component({
  selector: 'app-inline-menu',
  templateUrl: './app.inlinemenu.component.html',
  animations: [
    trigger('menu', [
      state('hiddenAnimated', style({
        height: '0px',
        paddingBottom: '0px',
        overflow: 'hidden'
      })),
      state('visibleAnimated', style({
        height: '*',
        overflow: 'visible'
      })),
      state('visible', style({
        opacity: 1,
        'z-index': 100
      })),
      state('hidden', style({
        opacity: 0,
        'z-index': '*'
      })),
      transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('visible => hidden', animate('.1s linear')),
      transition('hidden => visible', [style({transform: 'scaleY(0.8)'}), animate('.12s cubic-bezier(0, 0, 0.2, 1)')])
    ])
  ]
})
export class AppInlineMenuComponent implements OnInit {

  get currentBackgroundStyles() {
    return {
      'background-image': `url("${MediaObjectPipe.transformToLink(this.user.background, {defaultImageType: 'background'})}")`,
      'background-size': 'cover',
      'background-position': 'center'
    };
  }

  get user(): CurrentUserViewModel {
    return this._user;
  }

  @Input() set user(value: CurrentUserViewModel) {
    this._user = value;
  }

  @Input() key = "inline-menu";

  @Input() style: any;

  @Input() styleClass: string;

  private _user: CurrentUserViewModel;

  active: boolean;

  constructor(public appMain: SiteLayoutComponent, public app: AppComponent, private ts: TokenDataProviderService) {
  }

  ngOnInit(): void {
  }

  onClick(event) {
    return; // temporary disabled switch for side menu
    this.appMain.onInlineMenuClick(event, this.key);
    event.preventDefault();
  }

  get isTooltipDisabled() {
    return !(this.appMain.isSlim() && !this.appMain.isMobile());
  }

  get tabIndex() {
    return !this.appMain.inlineMenuActive ? '-1' : null;
  }

  isHorizontalActive() {
    return this.appMain.isHorizontal() && !this.appMain.isMobile();
  }

  public logout(event: Event): void {
    this.ts.logout();
  }

  public onClickNavigationElement(): void {
    this.appMain.resetActiveMenus();
  }
}
