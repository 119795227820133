import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable, Subject} from "rxjs";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {plainToClass, plainToClassFromExist} from "class-transformer";
import {ResultListApiModel} from "../models/result-list.api.model";
import {VehicleApiModel} from "../models/vehicle.api-model";
import {QueryParamsService} from "../../core/services/query-params.service";
import {QueryParamsApiModel} from "../models/query-params-api.model";
import {VehicleTypeApiModel} from "../models/vehicle-type.api-model";
import {UserApiModel} from "../models/user.api-model";

@Injectable()
export class VehicleRestService {
  public vehicleType: VehicleTypeApiModel;
  private readonly resource: string = '/vehicles';

  constructor(
    public http: HttpClient,
    public params: QueryParamsService
  ) {
  }

  public getVehicleCollection(queryParamsApiModel: QueryParamsApiModel = new QueryParamsApiModel()): Observable<ResultListApiModel<VehicleApiModel>> {
    const params = this.params.applyParameters(queryParamsApiModel);
    return this.http.get<ResultListApiModel<VehicleApiModel>>(environment.apiUrl + this.resource, {params})
      .pipe(map(result => plainToClassFromExist(new ResultListApiModel<VehicleApiModel>(VehicleApiModel), result as object)));
  }

  public get(id: string): Observable<VehicleApiModel> {
    return this.http.get<VehicleApiModel>(environment.apiUrl + id)
      .pipe(map(successResponse => plainToClass(VehicleApiModel, successResponse)));
  }

  public delete(vehicleAM: VehicleApiModel): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(environment.apiUrl + vehicleAM['@id']);
  }
}
